var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CyDetails',{attrs:{"item-id":_vm.configRepositoryCanonical,"on-save":_vm.onSave,"on-cancel":_vm.onCancel,"on-delete":_vm.$toggle.showDeleteDialog,"can-cancel":_vm.canCancel,"can-save":_vm.canSave,"loading":_vm.isLoading,"saving":_vm.isSaving,"deleting":_vm.isDeleting,"is-read-only":!_vm.canUpdateConfigRepository}},[_c('template',{slot:"details_form"},[_c('CyAlert',{attrs:{"theme":"error","content":_vm.errors}}),_c('v-text-field',{staticClass:"required-field",attrs:{"label":_vm.$t('forms.fieldName'),"error-messages":_vm.nameErrors,"disabled":!_vm.canUpdateConfigRepository,"readonly":!_vm.canUpdateConfigRepository,"required":"","data-cy":"cr-name-input"},on:{"blur":function($event){return _vm.$v.formData.name.$touch()}},model:{value:(_vm.$v.formData.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.formData.name, "$model", $$v)},expression:"$v.formData.name.$model"}}),_c('CySearchBox',{staticClass:"required-field has-copy-btn",attrs:{"error-messages":_vm.urlErrors,"disabled":_vm.isUrlReadOnly,"readonly":_vm.isUrlReadOnly,"hint":_vm.hints.urlGithub,"persistent-hint":!_vm.$isCreationRoute,"required":"","clearable":"","data-cy":"cr-url-input"},model:{value:(_vm.$v.formData.url.$model),callback:function ($$v) {_vm.$set(_vm.$v.formData.url, "$model", $$v)},expression:"$v.formData.url.$model"}},[(!_vm.$isCreationRoute && _vm.canUpdateConfigRepository)?_c('v-icon',{attrs:{"slot":"append-outer","color":"darkgrey"},on:{"click":_vm.$toggle.isUrlLocked},slot:"append-outer"},[_vm._v(" "+_vm._s(_vm.isUrlLocked ? 'lock' : 'lock_open')+" ")]):_vm._e(),_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(_vm._s(_vm.$t('untranslated.URL')))]),(!_vm.$v.formData.url.$invalid)?_c('CyCopyBtn',{attrs:{"slot":"append","copy-value":_vm.formData.url},slot:"append"}):_vm._e()],1),(_vm.showCredentials)?_c('CyCredentials',{class:['mb-3', {
          'mt-5': !_vm.$isCreationRoute,
          'required-field': _vm.isPrivate,
        }],attrs:{"clearable":"","disabled":!_vm.canUpdateConfigRepository,"readonly":!_vm.canUpdateConfigRepository,"error-messages":_vm.credentialCanonicalErrors,"hint":_vm.isPrivate ? _vm.$t('credentialRequiredForBranches') : null,"items":_vm.filteredCredentials,"label":_vm.$t('Credential'),"required":_vm.isPrivate},on:{"blur":function($event){return _vm.$v.formData.credential_canonical.$touch()}},model:{value:(_vm.$v.formData.credential_canonical.$model),callback:function ($$v) {_vm.$set(_vm.$v.formData.credential_canonical, "$model", $$v)},expression:"$v.formData.credential_canonical.$model"}}):_vm._e(),(_vm.showBranches)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-select',{staticClass:"required-field flex-1-1-0",attrs:{"disabled":!_vm.canFetchBranches || !_vm.canUpdateConfigRepository,"readonly":!_vm.canUpdateConfigRepository,"items":_vm.branches,"menu-props":{ offsetY: true },"label":_vm.$t('forms.fieldBranch'),"hint":_vm.hints.fetchBranches,"persistent-hint":"","hide-selected":"","required":"","data-cy":"branch-select"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"black--text"},[_vm._v(" "+_vm._s(item)+" ")])]}},{key:"item",fn:function(ref){
        var item = ref.item;
return [_c('v-list-item-content',{attrs:{"data-cy":"branch-list-item"}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item)+" ")])],1)]}}],null,false,2015467952),model:{value:(_vm.$v.formData.branch.$model),callback:function ($$v) {_vm.$set(_vm.$v.formData.branch, "$model", $$v)},expression:"$v.formData.branch.$model"}}),(_vm.canUpdateConfigRepository)?_c('CyTooltip',{attrs:{"right":"","transition":"slide-x-transition","disabled":!_vm.canFetchBranches},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var refreshTooltip = ref.on;
return [_c('span',_vm._g({staticClass:"align-self-center ml-5"},refreshTooltip),[_c('CyButton',{attrs:{"readonly":!_vm.canFetchBranches,"disabled":!_vm.canFetchBranches,"loading":_vm.isFetchingBranches,"icon":"refresh","variant":"secondary","icon-only":"","sm":""},on:{"click":_vm.getBranches}})],1)]}}],null,false,3920633564)},[_c('span',[_vm._v(" "+_vm._s(_vm.isFetchingBranches ? _vm.$t('refreshingBranches') : _vm.$t('refreshBranches'))+" ")])]):_vm._e()],1):_vm._e(),(!_vm._.isEmpty(_vm.branches))?_c('v-switch',{staticClass:"required-field default-switch",attrs:{"label":_vm.$t('untranslated.default'),"hint":_vm.$t('fieldSwitchHint'),"disabled":!_vm.canUpdateConfigRepository,"readonly":!_vm.canUpdateConfigRepository,"persistent-hint":"","required":"","color":"accent"},model:{value:(_vm.$v.formData.default.$model),callback:function ($$v) {_vm.$set(_vm.$v.formData.default, "$model", $$v)},expression:"$v.formData.default.$model"}}):_vm._e()],1),_c('template',{slot:"details_formAside"},[(!_vm.$isCreationRoute)?_c('div',{staticClass:"stacks__container"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$sanitizeHtml(_vm.$tc('projectsUsingConfigRepository', _vm.projects.length, { projectsCount: _vm.projects.length })))}}),_c('CyTagList',{staticClass:"stacks__list",attrs:{"small":"","tags":_vm.projects},scopedSlots:_vm._u([{key:"tag",fn:function(ref){
        var tag = ref.tag;
return [_c('CyTag',{staticClass:"clickable",attrs:{"variant":"primary","data-cy":"project-tag","element-type":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
                name: 'projectConfiguration',
                params: { projectCanonical: tag.canonical },
              })}}},[_vm._v(" "+_vm._s(tag.name)+" ")])]}}],null,false,147926756)})],1):_vm._e()])],2),(_vm.showDeleteDialog)?_c('CyModal',{attrs:{"header-title":_vm.$t('confirmDeleteHeader'),"action-btn-func":_vm.onDeleteConfirm,"cancel-btn-func":function () { return _vm.$toggle.showDeleteDialog(false); },"modal-type":"delete","small":""}},[_c('p',[_vm._v(_vm._s(_vm.$t('confirmDeleteSentence')))]),_c('h3',[_vm._v(_vm._s(_vm.formData.name))]),_c('p',{staticClass:"url"},[_vm._v(" "+_vm._s(_vm.formData.url)+" ")]),_c('p',{staticClass:"ma-0"},[_vm._v(" "+_vm._s(_vm.$t('confirmDeleteRepository'))+" ")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }